
import { Options, mixins } from "vue-class-component";
import { Forms } from "@/mixins";
import { copy } from "@/utils";
import { useToast } from "balm-ui";
import { minLength, required, email, maxLength } from "@vuelidate/validators";
import { UserInterface } from "@/types";
import { usersModule } from "@/store";
import {
  upperCase,
  numericCharacter,
  lowerCase,
  specialCharacter,
  onlyAlpha,
} from "@/validators";
import AlertsError from "@/components/forms/alerts/error/index.vue";

const $toast = useToast();

@Options({
  name: "UsersForm",
  components: { AlertsError },
  validations() {
    const rules = {
      formData: {
        email: {
          required,
          email,
        },
        role: {
          required,
          onlyAlpha,
        },
      },
      newPassword: {
        password: {},
      },
    };

    const passwordRules = {
      password: {
        required,
        minLength: minLength(7),
        maxLength: maxLength(32),
        upperCase,
        lowerCase,
        specialCharacter,
        numericCharacter,
      },
    };

    if (!this.modalOpen) {
      if (!this.isEditSubRoute) {
        rules.formData = { ...rules.formData, ...passwordRules };
      }
    } else {
      rules.newPassword = passwordRules;
    }

    return rules;
  },
  watch: {
    hasModalOpen(val) {
      if (val) {
        this.v$.$reset();
        this.newPassword = { password: "" };

        if (this.$refs.newPassword) {
          this.$refs.newPassword.inputValue = "";
        }
      }
    },
  },
})
export default class UsersForm extends mixins(Forms) {
  $refs!: {
    newPassword: HTMLFormElement;
  };
  private itemId!: string;
  private modalOpen = false;
  private form: UserInterface = {
    email: "",
    role: "",
  };
  private mInput: { [key: string]: string } = {
    password: "",
  };

  private get formData(): UserInterface {
    return this.form;
  }

  private set formData(data: UserInterface) {
    this.form = data;
  }

  private get newPassword(): { [key: string]: string } {
    return this.mInput;
  }

  private addUser(): void {
    this.roastMessage = "Added!";
    usersModule.Add(this.formData);
  }

  private EditUser(): void {
    this.roastMessage = "Saved!";
    usersModule.Edit({ id: this.itemId, payload: this.formData });
  }

  private onSave(): void {
    this.isSaved = true;
    this.isEditSubRoute ? this.EditUser() : this.addUser();

    $toast({ timeoutMs: this.toastTimeOut, message: this.roastMessage });

    setTimeout(() => {
      this.isSaved = false;
      this.$router.push({ name: "Users" });
    }, this.toastTimeOut);
  }

  private onConfirm(result: any): void {
    if (this.newPassword.password) {
      this.formData = { ...this.formData, ...this.newPassword };
    }
  }

  created(): void {
    let params = copy(this.$route.params);

    if (this.isEditSubRoute && !Object.keys(params).length) {
      this.$router.go(-1);
    } else {
      this.itemId = params.id;
      this.formData = { ...this.formData, ...params };

      if (!this.isEditSubRoute) {
        this.formData = { ...this.formData, ...{ password: "" } };
      }
    }
  }
}
