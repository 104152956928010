
import { Options, Vue } from "vue-class-component";
import { usersModule } from "@/store";
import { UserInterface } from "@/types";
import { copy, search } from "@/utils";
import { useToast } from "balm-ui";
import moment from "moment";
import PageWrapper from "@/layout/pageWrapper/index.vue";
import ZeroState from "@/components/ui/zeroState/index.vue";

@Options({
  name: "Users",
  components: { PageWrapper, ZeroState },
  watch: {
    page(val) {
      this.tableData = this.getItemsPerPage(val, this.getAssets);
    },
  },
})
export default class Users extends Vue {
  private thead: any = [
    {
      value: "Id",
      sort: "asc",
      columnId: "id",
    },
    "Email",
    "Role",
    "Created",
    "Actions",
  ];
  private tbody: any = [
    {
      field: "id",
      width: 100,
      fixed: "left",
    },
    "email",
    "role",
    {
      field: "createdAt",
      fn: (data: UserInterface) => {
        return moment(data.createdAt).format("DD/MM/YYYY");
      },
    },
    {
      width: 100,
      slot: "actions",
    },
  ];
  private selectedRows: Array<number> = [];
  private uData: UserInterface[] = [];
  private activeRowData!: UserInterface | null;
  private actionType = "Create";
  private page = 1;
  private readonly itemsToShowPerPage = 10;
  private open = false;

  private get isSubRoute(): boolean {
    const subRoutes = ["Create", "Edit"];

    return this.$route.matched.some(({ name }: { name: any }) =>
      subRoutes.some((sRoute: string) => name.indexOf(sRoute) > -1)
    );
  }

  private get getUsers(): UserInterface[] {
    if (!usersModule.getUsers) return [];
    return copy(usersModule.getUsers);
  }

  private get tableData(): UserInterface[] {
    return this.uData;
  }

  private set tableData(val: UserInterface[]) {
    this.uData = val;
  }

  private get getUsersLength(): number {
    return this.getUsers.length;
  }

  private getItemsPerPage(
    page: number,
    source: UserInterface[]
  ): UserInterface[] {
    return source.slice(
      (page - 1) * this.itemsToShowPerPage,
      page * this.itemsToShowPerPage
    );
  }

  private editRow(data: any): void {
    this.activeRowData = data;
    this.onModalOpen("Edit");
  }

  private deleteRow(data: any): void {
    this.activeRowData = data;
    this.onModalOpen("Delete");
  }

  private onModalOpen(type: string): void {
    this.open = !this.open;
    this.actionType = type;
  }

  private onConfirm(result: any): void {
    const typeToRedirect = ["Create", "Edit"];
    const $toast = useToast();

    if (result) {
      if (typeToRedirect.includes(this.actionType)) {
        let routerData = { name: `Users/${this.actionType}` };

        if (this.activeRowData) {
          routerData = { ...routerData, ...{ params: this.activeRowData } };
        }
        this.$router.push(routerData);
      } else {
        usersModule.Delete([this.activeRowData?.id]);
        $toast(`${this.activeRowData?.email} is deleted!`);
      }

      this.activeRowData = null;
    }
  }

  private onDeleteSelectedRows(selectedRows: Array<number>): void {
    console.log(this.getUsers.filter((c, i) => selectedRows.includes(i + 1)));
  }

  private onSearch(str: string): void {
    const result = search({ sourceData: this.getUsers, str });
    this.tableData = this.getItemsPerPage(this.page, result);
  }

  created(): void {
    usersModule.Load();
  }

  mounted(): void {
    usersModule.$watch(
      (module) => module.getUsers,
      () => {
        this.tableData = this.getItemsPerPage(this.page, this.getUsers);
      }
    );

    this.emitter.on("search", (val: string) => this.onSearch(val));
  }
}
